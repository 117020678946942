<template>
  <div class="wrap">
    <Navbar :title="$t('page.agentReport')" :border="false" :fixed="true" />
    <div class="top_bar">
      <p class="date_label">{{ $t("teamReport.from") }}</p>
      <div class="input" @click="showFromPicker = true">{{ $format(fromValue, "yy-MM-dd") }}</div>
      <p class="date_label">{{ $t("teamReport.to") }}</p>
      <div class="input" @click="showToPicker = true">{{ $format(toValue, "yy-MM-dd") }}</div>
      <div class="input">
        <input @keydown.enter="getData" v-model="searchValue" :placeholder="$t('agentReport.searchPlaceholder')"
          type="text">
      </div>
    </div>
    <div class="table_bar" v-for="item, index in tables" :key="`table_wrap_${index}`">
      <Cell class="th" is-link :style="{ background: `${item.color}` }"
        :to="`/reportDetails?icon=${item.icon}&label=${item.label}&color=${item.color}&start_day=${fromValue}&end_day=${toValue}`">
        <template #title>
          <i class="iconfont" :class="item.icon"></i>
          <span>{{ $t("agentReport.label." + item.label) }}</span>
        </template>
      </Cell>
      <div class="table_item" v-for="c_item, c_index in item.items" :key="`table_${index}_${c_index}`">
        <div class="td">{{ $t(`agentReport.label.${c_item.label}`) }}</div>
        <div class="td" :style="{ color: c_item.green ? 'var(--green)' : '' }">{{ data[c_item.value] || 0 }}</div>
      </div>
    </div>
    <!-- 开始时间选择 -->
    <Popup v-model="showFromPicker" position="bottom">
      <DatetimePicker v-model="fromValue" type="date" title="" @confirm="(value) => dateSelectChange('from', value)"
        @cancel="showFromPicker = false" :confirm-button-text="$t('My.Button.Two')"
        :cancel-button-text="$t('My.Button.One')" />
    </Popup>
    <!-- 结束时间选择 -->
    <Popup v-model="showToPicker" position="bottom">
      <DatetimePicker v-model="toValue" type="date" title="" @confirm="(value) => dateSelectChange('to', value)"
        :min-date="fromValue" @cancel="showToPicker = false" :confirm-button-text="$t('My.Button.Two')"
        :cancel-button-text="$t('My.Button.One')" />
    </Popup>
  </div>
</template>

<script>
import Navbar from "@/components/JNav"
import { Popup, Cell, DatetimePicker } from "vant";
import $format from "@/utils/dateFormat"
import { post } from "@/utils/newRequest"
export default {
  components: { Navbar, Popup, Cell, DatetimePicker },
  data() {
    this.tables = [
      {
        icon: "icon-baobiao2", label: "total", color: "red",
        items: [
          { label: "recharge", value: "pay_money", green: true },
          { label: "withdraw", value: "extract_money" },
        ]
      }
    ]
    return {
      fromValue: new Date(),
      toValue: new Date(),
      showFromPicker: false,
      showToPicker: false,
      searchValue: "",
      data: {}
    }
  },
  created() {
    this.getData();
  },
  methods: {
    dateSelectChange(which, value) {
      if (which == 'from') {
        this.showFromPicker = false
      } else {
        this.showToPicker = false
      }
      this[`${which}Value`] = value
      this.getData();
    },
    async getData() {
      try {
        const res = await post("/users/ref/userinfo",
          { act: "personal", start_day: $format(this.fromValue, "yyyy-MM-dd"), end_day: $format(this.toValue, "yyyy-MM-dd"), key: this.searchValue });
        this.data = res;
      } catch (error) {
        if (error.msg) this.$toast(error.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.top_bar {
  padding: 0 20px;
  border-bottom: 1px solid var(--gray1);
  padding-bottom: 10px;

  .date_label {
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0;
    color: var(--textColor)
  }

  .input {
    padding: 10px 18px;
    border-radius: 6px;
    background: var(--light);
    border: 1px solid var(--light);
    color: var(--textColor);

    &:nth-last-of-type(1) {
      margin-top: 12px;

      >input {
        border: none;
        width: 100%;
        background-color: transparent;
        &::placeholder{
          color: var(--gray1);
        }
      }
    }
  }
}

.table_bar {
  margin-top: 20px;
  padding: 0 8px;
  @gap: 6px;

  .th {
    color: var(--light);
    font-size: 14px;
    margin-bottom: @gap;
    border-radius: 4px;
    background: var(--red);

    span {
      font-weight: 700;
      margin-left: 4px;
    }

    /deep/ .van-cell__right-icon {
      color: var(--light);
    }
  }

  .table_item {
    --green: #28ba62;
    display: flex;
    margin-bottom: @gap;

    .td {
      width: calc((100% - @gap) / 2 - 8px);
      font-size: 14px;
      color: var(--textColor);
      border-radius: 4px;
      padding: 4px;
      border: 1px solid var(--light);
      font-weight: bold;
      background: var(--light);

      &:nth-of-type(2) {
        text-align: end;
      }

      &:nth-of-type(2n) {
        margin-left: @gap;
      }
    }

    // &:nth-of-type(1) {
    //   background: red;
    //   .td {
    //     &:nth-of-type(2) {
    //       color: #28ba62;
    //     }
    //   }
    // }
  }
}
</style>